.article-wysiwyg {
    p+h2,
    p+h3,
    p+h4,
    p+h5,
    p+h6 {
        margin-top: 2rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $font-weight-semibold;
        margin-bottom: 1.25rem;
    }

    img {
        @include img-fluid;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            &.align-left {
                max-width: 50%;
                float: left;
                margin-top: .5rem;
                margin-right: 1.5rem;
            }

            &.align-right {
                max-width: 50%;
                float: right;
                margin-top: .5rem;
                margin-left: 1.5rem;
            }
        }

    }
}