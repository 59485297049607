@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans-Regular.woff2') format('woff2'),
        url('../fonts/NunitoSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans-Bold.woff2') format('woff2'),
        url('../fonts/NunitoSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans-SemiBold.woff2') format('woff2'),
        url('../fonts/NunitoSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/NunitoSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

