.black-uline {
  border: solid 1px black !important;
}

@media (max-width: 576px) {
  .sm-display-direction {
    flex-direction: column;
  }
}

