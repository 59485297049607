.members-card {
  position: relative;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  margin-bottom: 0;
  border-radius: 50%;

  &-img {
    display: inline-flex;
    text-align: center;
    color: $white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@media (min-width: 576px) and (max-width: 633px) {
  .img-responsive {
    width: 159px;
    // height: auto;
  }
}

@media (min-width: 633px) and (max-width: 767px) {
  .img-responsive {
    width: 200px;
    // height: auto;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .img-responsive {
    width: 215px;
    // height: auto;
  }
}