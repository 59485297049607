.pagination-rounded {
    .page-item:not(:first-child):not(:last-child) {
        .page-link {
            border-radius: 50%;
            width: 2.5rem;
            padding-left: .5rem;
            padding-right: .5rem;
            text-align: center;
        }
    }
    &.pagination-sm {
        .page-item:not(:first-child):not(:last-child) {
            .page-link {
                height: 2rem;
                width: 2rem;
                padding-left: .25rem;
                padding-right: .25rem;
            }
        }
    }
}
