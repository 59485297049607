$form-switch-md: 1.5rem !default;
$form-switch-lg: 2rem !default;



.form-check-input {
    margin-top: 0.0625em;
}
// switchs
.form-switch-md {
    padding-left: $form-switch-md * 2.5;

    .form-check-input {
        margin-top: 0;
        font-size: $form-switch-md;
        margin-left: $form-switch-md * -2.5;
    }

    &.form-check-reverse {
        padding-right: $form-switch-md * 2.5;

        .form-check-input {
            margin-right: $form-switch-md * -2.5;
        }
    }
}

.form-switch-lg {
    padding-left: $form-switch-lg * 2.5;
    min-height: $form-switch-lg;

    .form-check-input {
        margin-top: .02em;
        font-size: $form-switch-lg;
        margin-left: $form-switch-lg * -2.5;
    }

    &.form-check-reverse {
        padding-right: $form-switch-lg * 2.5;

        .form-check-input {
            margin-right: $form-switch-lg * -2.5;
        }
    }
    .form-check-label {
        margin-top: .25em;
    }
}

// files 
.form-file {
    &-input {
        @include visually-hidden();

        &:focus + .form-file-btn {
            box-shadow: $btn-focus-box-shadow
        }
    }
    &-btn {
        border-width: 2px !important;
        border-style: dashed !important;
    }

}

.form-label .required {
    color: #CD334F;
    text-decoration: none;
    padding-left: .25rem;
}

.form-select {
 &:required:invalid {
    color: $gray-600;
  }
  option[value=""][disabled] {
    display: none;
  }
  option {
    color: $body-color
  }
}

.input-group-search {
    .form-control {
        padding-right: 3rem + $input-padding-x;
        border-top-right-radius: $input-border-radius !important;
        border-bottom-right-radius:  $input-border-radius !important;
    }
    .btn-search {
        padding: 0;
        border: 1px solid transparent;
        width: 3rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: $input-border-radius !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-left: -3rem !important;
        z-index: 5;

        &:hover {
            color: $primary;
        }

        &:focus-visible {
            color: $primary;
            border-color: $primary;
            outline: 0;
            box-shadow: $btn-focus-box-shadow
          }
    }
}
.input-group-lg.input-group-search {
    .form-control {
        padding-right: 3rem + $input-padding-x-lg;
    }
}

.form-legend {
    font-size: 1rem;
}

// form collapse 
.form-select-button {
    display: flex;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    align-items: center;
    line-height: 1;

    &::after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
    }
  
    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);
  
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        // @if $enable-shadows {
        //   @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        // } @else {
        //   // Avoid using mixin so we can pass custom focus shadow properly
        //   box-shadow: $input-focus-box-shadow;
        // }
      }
}

.form-select-button:not(.collapsed)::after {
    transform: rotate(-180deg);
}

.form-select-button-filter:not(.collapsed)::after {
    transform: rotate(-180deg);
}


.form-select-button-sm {
    min-height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    @include font-size($input-font-size-sm);
    @include border-radius($input-border-radius-sm);
  }
  
  .form-select-button-lg {
    min-height: $input-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    @include font-size($input-font-size-lg);
    @include border-radius($input-border-radius-lg);
  }

.form-select-collapse {

}

.form-select-option-list {
    column-count: 2;
    margin-top: .5rem;
    @include list-unstyled;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    margin-bottom: 0;
    padding: 1.375rem 1.25rem 1.25rem 1.25rem;
}

.form-select-option-filter {
    column-count: 1;
    margin-top: .5rem;
    @include list-unstyled;
    border-radius: $input-border-radius;
    margin-bottom: 0;
    padding: 1.375rem 1.25rem 1.25rem 1.25rem;
}

.btn-search-icon {
    z-index: 5 !important;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 !important;
}


// my picp checkbox

.form-select-button-filter {
    display: flex;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    align-items: center;
    line-height: 1;

    &::after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
    }
  
    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);
  
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        // @if $enable-shadows {
        //   @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        // } @else {
        //   // Avoid using mixin so we can pass custom focus shadow properly
        //   box-shadow: $input-focus-box-shadow;
        // }
      }
}

