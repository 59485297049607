.hr-line {
  margin-left: 2rem;
  margin-right: 2rem;
}

@media screen and (max-width: 480px) {
  .media-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .gmb-media-all {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .gmb-media {
    align-items: center;
    justify-content: space-around;
    padding-top: 7px;
  }
}

.board-list {
  &-item {
  }
  &-control {
    height: 0;
    width: 0;
  }
  &-control {
    &:checked + .board-list-label {
      color: $white;
      background-color: $primary;
    }
    &:focus + .board-list-label {
      border-color: $primary;
      box-shadow: $btn-focus-box-shadow;
    }
  }
  &-label {
    transition: 0.15s ease-in-out;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $border-color;
    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}


.nav-color {
  &-bottom {
    transition: 0.15s ease-in-out;
    border-bottom: solid 3px transparent;
    &:hover {
      color: $teal!important;
      border-bottom: solid 3px;
      border-color: $teal;
    }
  }
}
