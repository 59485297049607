@each $color-key, $color-value in $grays {
    .text-gray-#{$color-key} {
        color: #{$color-value} !important;
    }
    .border-gray-#{$color-key} {
        border-color: #{$color-value} !important;
    }
    .bg-gray-#{$color-key} {
        background-color: #{$color-value} !important;
    }  
}

@each $color-key, $color-value in $colors {
    .text-#{$color-key} {
        color: #{$color-value} !important;
    }
    .border-#{$color-key} {
        border-color: #{$color-value} !important;
    }
    .bg-#{$color-key} {
        background-color: #{$color-value} !important;
    }  
}




// font sizes 
.fs {
    &-small {
        font-size: $small-font-size  !important;
    }

    &-normal {
        font-size: $font-size-base  !important;
    }
}

.link {
    color: inherit;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    &-inverse {
        color: inherit;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}


.min-vh-header {
    min-height: calc(100vh - 130px);

    @include media-breakpoint-up('xl') {
        min-height: calc(100vh - 152px);
    }
}

.max-wh {
    &-md {
        max-height: 2rem;
        max-width: 2rem;
    }
}

.bg-cyan-100 {
    background-color: $cyan-100 !important;
}

// .bg-primary-100 {
//     background-color: $primary-100 !important;
// }

// .bg-info-100 {
//  background-color: $info-100 !important;   
// }

// .bg-light-100 {
//     background-color: $info-100 !important;   
//    }

//    .bg-secondary-100 {
//     background-color: $secondary-100 !important;   
//    }

//    .bg-indigo-100 {
//     background-color: $indigo-100 !important;   
//    }