.main-footer {
   
    .contact-info {
        display: flex;
        &-item {
            display: flex;
        }
    }

}
.z-index-999 {
    z-index: 999;
}