/*!*****************************************************

Freak Flags, Copyright ©2022 Michael P. Cohen. Freak flags is licenced under the MIT licence.  

For complete information visit: www.freakflagsprite.com 

******************************************************/

.flag {
    background-image:url("../images/static/flag-sprite.png");
    background-repeat:no-repeat;
    background-size: 100% 49494%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    box-sizing: content-box;
}

.flag-sm {width: 18px;height: 11px}
.flag-md {width: 27px;height: 17px}
.flag-lg {width: 42px;height: 27px}
.flag-xl {width: 60px;height: 37px}

.flag-rounded {
    border-radius: 50%;
    &.flag-md {width: 18px;height: 18px}
} 


.flag-CH,
.flag-NP {box-shadow: none!important}
.flag-DZ {background-position:center 0.2287%}
.flag-AO {background-position:center 0.4524%}
.flag-BJ {background-position:center 0.6721%}
.flag-BW {background-position:center 0.8958%}
.flag-BF {background-position:center 1.1162%}
.flag-BI {background-position:center 1.3379%}
.flag-CM {background-position:center 1.5589%}
.flag-CV {background-position:center 1.7805%}
.flag-CF {background-position:center 2.0047%}
.flag-TD {background-position:center 2.2247%}
.flag-CD {background-position:left 2.4467%}
.flag-DJ {background-position:left 2.6674%}
.flag-EG {background-position:center 2.8931%}
.flag-GQ {background-position:center 3.1125%}
.flag-ER {background-position:left 3.3325%}
.flag-ET {background-position:center 3.5542%}
.flag-GA {background-position:center 3.7759%}
.flag-GM {background-position:center 4.0015%}
.flag-GH {background-position:center 4.2229%}
.flag-GN {background-position:center 4.441%}
.flag-GW {background-position:left 4.66663%}
.flag-CI {background-position:center 4.8844%}
.flag-KE {background-position:center 5.1061%}
.flag-LS {background-position:center 5.3298%}
.flag-LR {background-position:left 5.5495%}
.flag-LY {background-position:center 5.7712%}
.flag-MG {background-position:center 5.994%}
.flag-MW {background-position:center 6.2156%}
.flag-ML {background-position:center 6.4363%}
.flag-MR {background-position:center 6.658%}
.flag-MU {background-position:center 6.8805%}
.flag-YT {background-position:center 7.1038%}
.flag-MA {background-position:center 7.3231%}
.flag-MZ {background-position:left 7.5448%}
.flag-NA {background-position:left 7.7661%}
.flag-NE {background-position:center 7.98937%}
.flag-NG {background-position:center 8.2099%}
.flag-CG {background-position:center 8.4316%}
.flag-RE {background-position:center 8.6533%}
.flag-RW {background-position:right 8.875%}
.flag-SH {background-position:center 9.0967%}
.flag-ST {background-position:center 9.32237%}
.flag-SN {background-position:center 9.5426%}
.flag-SC {background-position:left 9.7628%}
.flag-SL {background-position:center 9.9845%}
.flag-SO {background-position:center 10.2052%}
.flag-ZA {background-position:left 10.4269%}
.flag-SS {background-position:left 10.6486%}
.flag-SD {background-position:center 10.8703%}
.flag-SR {background-position:center 11.0945%}
.flag-SZ {background-position:center 11.3135%}
.flag-TG {background-position:left 11.5354%}
.flag-TN {background-position:center 11.7593%}
.flag-UG {background-position:center 11.9799%}
.flag-TZ {background-position:center 12.2005%}
.flag-EH {background-position:center 12.4222%}
.flag-YE {background-position:center 12.644%}
.flag-ZM {background-position:center 12.8664%}
.flag-ZW {background-position:left 13.0873%}
.flag-AI {background-position:center 13.309%}
.flag-AG {background-position:center 13.5307%}
.flag-AR {background-position:center 13.7524%}
.flag-AW {background-position:left 13.9741%}
.flag-BS {background-position:left 14.1958%}
.flag-BB {background-position:center 14.4175%}
.flag-BQ {background-position:center 14.6415%}
.flag-BZ {background-position:center 14.8609%}
.flag-BM {background-position:center 15.0826%}
.flag-BO {background-position:center 15.306%}
.flag-VG {background-position:center 15.528%}
.flag-BR {background-position:center 15.7496%}
.flag-CA {background-position:center 15.9694%}
.flag-KY {background-position:center 16.1911%}
.flag-CL {background-position:left 16.4128%}
.flag-CO {background-position:left 16.6345%}
.flag-KM {background-position:center 16.8562%}
.flag-CR {background-position:center 17.0779%}
.flag-CU {background-position:left 17.2996%}
.flag-CW {background-position:center 17.5213%}
.flag-DM {background-position:center 17.743%}
.flag-DO {background-position:center 17.968%}
.flag-EC {background-position:center 18.1864%}
.flag-SV {background-position:center 18.4081%}
.flag-FK {background-position:center 18.6298%}
.flag-GF {background-position:center 18.8515%}
.flag-GL {background-position:left 19.0732%}
.flag-GD {background-position:center 19.2987%}
.flag-GP {background-position:center 19.518%}
.flag-GT {background-position:center 19.7383%}
.flag-GY {background-position:center 19.96%}
.flag-HT {background-position:center 20.1817%}
.flag-HN {background-position:center 20.4034%}
.flag-JM {background-position:center 20.6241%}
.flag-MQ {background-position:center 20.8468%}
.flag-MX {background-position:center 21.0685%}
.flag-MS {background-position:center 21.2902%}
.flag-NI {background-position:center 21.5119%}
.flag-PA {background-position:center 21.7336%}
.flag-PY {background-position:center 21.9553%}
.flag-PE {background-position:center 22.177%}
.flag-PR {background-position:left 22.4002%}
.flag-BL {background-position:center 22.6204%}
.flag-KN {background-position:center 22.8421%}
.flag-LC {background-position:center 23.0638%}
.flag-PM {background-position:center 23.2855%}
.flag-VC {background-position:center 23.5072%}
.flag-SX {background-position:left 23.732%}
.flag-TT {background-position:center 23.9506%}
.flag-TC {background-position:center 24.1723%}
.flag-US {background-position:center 24.394%}
.flag-VI {background-position:center 24.6157%}
.flag-UY {background-position:left 24.8374%}
.flag-VE {background-position:center 25.0591%}
.flag-AB {background-position:center 25.279%}
.flag-AF {background-position:center 25.5025%}
.flag-AZ {background-position:center 25.7242%}
.flag-BD {background-position:center 25.9459%}
.flag-BT {background-position:center 26.1676%}
.flag-BN {background-position:center 26.3885%}
.flag-KH {background-position:center 26.611%}
.flag-CN {background-position:left 26.8327%}
.flag-GE {background-position:center 27.0544%}
.flag-HK {background-position:center 27.2761%}
.flag-IN {background-position:center 27.4978%}
.flag-ID {background-position:center 27.7195%}
.flag-JP {background-position:center 27.9412%}
.flag-KZ {background-position:center 28.1615%}
.flag-LA {background-position:center 28.3846%}
.flag-MO {background-position:center 28.6063%}
.flag-MY {background-position:center 28.829%}
.flag-MV {background-position:center 29.0497%}
.flag-MN {background-position:left 29.2714%}
.flag-MM {background-position:center 29.4931%}
.flag-NP {background-position:left 29.7148%}
.flag-KP {background-position:left 29.9365%}
.flag-MP {background-position:center 30.1582%}
.flag-PW {background-position:center 30.3799%}
.flag-PG {background-position:center 30.6016%}
.flag-PH {background-position:left 30.8233%}
.flag-SG {background-position:left 31.045%}
.flag-KR {background-position:center 31.2667%}
.flag-LK {background-position:right 31.4884%}
.flag-TW {background-position:left 31.7101%}
.flag-TJ {background-position:center 31.9318%}
.flag-TH {background-position:center 32.1535%}
.flag-TL {background-position:left 32.3752%}
.flag-TM {background-position:center 32.5969%}
.flag-VN {background-position:center 32.8186%}
.flag-AX {background-position:center 33.0403%}
.flag-AL {background-position:center 33.25975%}
.flag-AD {background-position:center 33.4837%}
.flag-AM {background-position:center 33.7054%}
.flag-AT {background-position:center 33.9271%}
.flag-BY {background-position:left 34.1488%}
.flag-BE {background-position:center 34.3705%}
.flag-BA {background-position:center 34.5922%}
.flag-BG {background-position:center 34.8139%}
.flag-HR {background-position:center 35.0356%}
.flag-CY {background-position:center 35.2555%}
.flag-CZ {background-position:left 35.479%}
.flag-DK {background-position:center 35.7007%}
.flag-EE {background-position:center 35.9224%}
.flag-FO {background-position:center 36.1441%}
.flag-FI {background-position:center 36.3658%}
.flag-FR {background-position:center 36.5875%}
.flag-DE {background-position:center 36.8092%}
.flag-GI {background-position:center 37.0309%}
.flag-GR {background-position:left 37.2526%}
.flag-GG {background-position:center 37.4743%}
.flag-HU {background-position:center 37.696%}
.flag-IS {background-position:center 37.9177%}
.flag-IE {background-position:center 38.1394%}
.flag-IM {background-position:center 38.3611%}
.flag-IT {background-position:center 38.5828%}
.flag-JE {background-position:center 38.8045%}
.flag-XK {background-position:center 39.0262%}
.flag-LV {background-position:center 39.2479%}
.flag-LI {background-position:left 39.4696%}
.flag-LT {background-position:center 39.6913%}
.flag-LU {background-position:center 39.913%}
.flag-MT {background-position:left 40.1347%}
.flag-MD {background-position:center 40.3564%}
.flag-MC {background-position:center 40.5781%}
.flag-ME {background-position:center 40.7998%}
.flag-NL {background-position:center 41.0215%}
.flag-MK {background-position:center 41.2432%}
.flag-NO {background-position:center 41.4649%}
.flag-PL {background-position:center 41.6866%}
.flag-PT {background-position:center 41.9083%}
.flag-RO {background-position:center 42.13%}
.flag-RU {background-position:center 42.3517%}
.flag-SM {background-position:center 42.5734%}
.flag-RS {background-position:center 42.7951%}
.flag-SK {background-position:center 43.0168%}
.flag-SI {background-position:center 43.2385%}
.flag-ES {background-position:left 43.4602%}
.flag-SE {background-position:center 43.6819%}
.flag-CH {background-position:center 43.9036%}
.flag-TR {background-position:center 44.1253%}
.flag-UA {background-position:center 44.347%}
.flag-GB {background-position:center 44.5687%}
.flag-VA {background-position:right 44.7904%}
.flag-BH {background-position:center 45.0121%}
.flag-IR {background-position:center 45.2338%}
.flag-IQ {background-position:center 45.4555%}
.flag-IL {background-position:center 45.6772%}
.flag-KW {background-position:left 45.897%}
.flag-JO {background-position:left 46.1206%}
.flag-KG {background-position:center 46.3423%}
.flag-LB {background-position:center 46.561%}
.flag-OM {background-position:left 46.7857%}
.flag-PK {background-position:center 47.0074%}
.flag-PS {background-position:center 47.2291%}
.flag-QA {background-position:center 47.4508%}
.flag-SA {background-position:center 47.6725%}
.flag-SY {background-position:center 47.8942%}
.flag-AE {background-position:center 48.1159%}
.flag-UZ {background-position:left 48.3376%}
