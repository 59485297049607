

.library-nav {
    .nav-item {
        position: relative;
    }

    .nav-item.active {
        .nav-link {
            position: relative;
            font-weight: bold;

            &::before {
                content: "";
                width: 3px;
                top: 0;
                left: - map-get($spacers, 7);
                margin-left: -1px;
                bottom: 0;
                position: absolute;
                background-color: $primary;
                display: block;
            }
        }
    }
}

.library-list {
.list-item {
    &:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }
    &:hover {
    background-color: $blue-50;
}
}
}

@include media-breakpoint-up('lg') {
    .library-body {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-left: 0 !important;
    }
    .library-side {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-right: 0 !important;
    }
}