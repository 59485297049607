

/* Default styles for the button */
.side-button {
  display: block; /* Show the button by default */
}

/* Media query for larger devices */
@media (min-width: 992px) {
  .side-button {
    display: none; /* Hide the button for devices with a minimum width of 992px */
  }
}
