.avatar {
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  margin-bottom: 0;
  border-radius: 50%;
  border: 2px solid $primary;

  &-initials {
    display: inline-flex;
    text-align: center;
    color: $white;
    background-color: $primary;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &-initials-edit {
    display: inline-flex;
    text-align: center;
    color: $white;
    background-color: $primary;
    width: 3rem;
    // height: 100%;
    border-radius: 50%;
  }

  &-img {
    display: inline-flex;
    text-align: center;
    color: $white;
    background-color: $primary;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &-cardimg {
    display: inline-flex;
    text-align: center;
    color: $cyan;
    background-color: $cyan;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid $cyan;
  }

  &-profile-img {
    display: inline-flex;
    text-align: center;
    color: $primary;
    background-color: $primary;
    width: 129px;
    height: 129px;
    border-radius: 50%;
    border: 6px solid $primary;
  }

  &-profile-page {
    display: inline-flex;
    text-align: center;
    color: $primary;
    background-color: $primary;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid $primary;
  }

  &-initials {
    font-weight: $font-weight-bold;
    justify-content: center;
    align-items: center;
  }

  &-xl {
    width: 3rem;
    height: 3rem;
  }

  &-xxl {
    width: 4.5rem;
    height: 4.5rem;
  }
}
