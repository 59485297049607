.video {
    overflow: hidden;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
 
    &-poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform .3s;
    }
 
 
    &-btn {
        position: absolute;
        z-index: 2;
    }




    // .svg-icon {
    //     color: $white;
    //     font-size: 4rem;


    //     @include media-breakpoint-up('lg') {
    //         font-size: 5rem;
    //     }
    // }
    // &:hover .video-cover {
    //     transform: scale(1.05);
    // }
}






.modal-video {
    .modal-header {
        border-color: transparent;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
      }
      .btn-close {
        margin: -1rem -1rem 1rem auto;
      }
}