.offcanvas-nav .list-nav {
    .nav-item {
        transition: background-color 0.15s ease-in-out;

        &:hover,
        &.active {
            background-color: $white;

            .nav-link {
                color: $primary;
            }
        }
    }

    .nav-link {
        color: $white;
    }
}

.col-search-side::before {
    content: "";
    width: 1px;
    height: 1px;
    background-color: $gray-400;

    @include media-breakpoint-down('xxl') {
        min-width: 100%;
        order: 1;
        margin: 1.5rem 0;
    }

    @include media-breakpoint-up('xxl') {
        min-height: 100%;
        margin: 0 1.5rem;
    }
}

.search-filter-list {
    margin-bottom: 0;

    &-tag {
        display: inline-flex;
        align-items: center;
        color: $body-color;
        padding: .25rem .5rem;
        border: 1px solid $gray-300;
        background-color: $white;
        border-radius: $border-radius-lg;
        font-size: $small-font-size;
        line-height: $line-height-sm;
        text-align: left;

        &::before {
            content: "";
            width: 1.75em;
            height: 1.75em;
            border-radius: 50%;
            display: inline-block;
            flex-shrink: 0;
            margin-right: .5rem;
            background: #FF6767 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 512 512'%3E%3Cpath d='m315 256l185-185c16-16 16-43 0-59-17-16-43-16-59 0l-185 185-185-185c-16-16-42-16-59 0-16 16-16 43 0 59l185 185-185 185c-16 16-16 43 0 59 8 8 19 12 30 12 10 0 21-4 29-12l185-185 185 185c8 8 19 12 30 12 10 0 21-4 29-12 16-16 16-43 0-59l-185-185z'%3E%3C/path%3E%3C/svg%3E") center/.72em auto no-repeat;
        }

        &:hover {
            border-color: #FF6767;
        }
    }
}

.search-result-item:not(:last-child) {
    margin-bottom: .875rem;
}

.search-filter-list+h2 {
    margin-top: 1.5rem;
}

.user-detail-list {
    >dd+dt {
        margin-top: 1rem;
    }

    >dd:last-child {
        margin-bottom: 0;
    }
}
