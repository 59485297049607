.list-check {
    @include list-unstyled;
    li {
        position: relative;
        padding-left: 2em;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            background: $primary url("data:") center / .6em no-repeat;
            border-radius: 50%;
        }
    }
}
