.specialist-networks-list {
  &-label {
    color: black;
    transition: 0.15s ease-in-out;
    cursor: pointer;
    border: 3px solid transparent;
    &:hover {
      border: 3px solid $border-color;
      border-color: $primary;
      background: white;
    }
  }
}

.arrow-left::after {
  content: "";
  background-image: url("../images/left@2x.png");
  border-radius: 50%;
  left: 2rem;
  border: 1px solid #4f8fcc;
  position: absolute;
  top: 0.85rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

.arrow-right::after {
  content: "";
  background-image: url("../images/left@2x.png");
  border-radius: 50%;
  left: 5rem;
  transform: rotate(180deg);
  border: 1px solid #4f8fcc;
  position: absolute;
  top: 0.85rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (max-width: 480px) {
  .media-direction {
    flex-direction: column;
  }
}

// .media-direction {
//   flex-direction: column;
// }