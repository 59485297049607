.input-center::-webkit-input-placeholder {
  text-align: center;
}
.input-center:-moz-placeholder {
  text-align: center;
}

.input-center {
  &:focus {
    border-bottom: solid 2px $cyan !important ;
    box-shadow: none !important;
  }
}

.cyan-title:has(input:focus) {
  color: $cyan !important;
}
