
@media screen and (max-width: 480px) {
    .media-direction {
        flex-direction: column;
       padding-top: 10px;
    }
  }


  @media screen and (max-width: 480px) {
    .button-position {
      justify-content: center !important;
      padding-top: 14px;
      padding-bottom: 12px;
    }
  }

//   @media screen and (max-width: 480px) {
//     .media-direction-reply {
//         flex-direction: column;
//        padding-top: 10px;
//        text-align: center;
//     }
//   }
