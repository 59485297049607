.membership-list {
    &-item {
    }
    &-control {
      height: 0;
      width: 0;
    }
    &-control {
      &:checked + .membership-list-label {
        color: $white;
        background-color: $primary;
      }
      &:focus + .membership-list-label {
        border-color: $primary;
        box-shadow: $btn-focus-box-shadow;
      }
    }
    &-label {
      transition: 0.15s ease-in-out;
      cursor: pointer;
      background-color: $white;
      border: 1px solid $border-color;
      &:hover {
        color: $primary;
        border-color: $primary;
      }
    }
  }

  .organization-type-list-control {
    height: 0;
    width: 0;
  }

  // .active-test {
  //   &:active {
  //       color: green;
  //   }
  //   &:focus {
  //       color: blue;
  //   }
  // }