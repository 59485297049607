
.offcanvas-sidebar-primary {
    background-color: $primary;
    border: none;
    min-height: 70vh;
  
    @include media-breakpoint-down('lg') {
       width: 80%;
    }

    @include media-breakpoint-up('lg') {
      position: static;
      transform: none !important;
      visibility: visible;
      border-radius: $border-radius-xl;
      z-index: 1 !important;
      border-right: none !important;
    }

    .btn-close {
        opacity: .8;
        &:hover {
            opacity: 1;
        }
    }

  }

  .offcanvas-sidebar-profile {
    // background-color: $primary;
    border: none;
    min-height: 50vh;
  
    @include media-breakpoint-down('lg') {
       width: 80%;
    }

    @include media-breakpoint-up('lg') {
      position: static;
      transform: none !important;
      visibility: visible;
      border-radius: $border-radius-xl;
      z-index: 1 !important;
      border-right: none !important;
    }

    .btn-close {
        opacity: .8;
        &:hover {
            opacity: 1;
        }
    }

  }

// offcanvas filter 
.accordion-filter-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    background-color: transparent;
    padding: .25rem 0;

    &::after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
    }
}

.accordion-filter-button:not(.collapsed)::after {
    transform: rotate(-180deg);
}


.form-check-light {
    .form-check-input {
        border-radius: .125rem;
        border-color: $white;


        &:focus {
            border-color: $white;
            box-shadow:  0 0 $input-btn-focus-blur $input-btn-focus-width rgba($white, $input-btn-focus-color-opacity);
          }

       &:checked {
            background-color: $white;
            border-color: $white;
            background-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%234F8FCC' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
        }
    }
}

