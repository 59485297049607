.main-header {
  top: 0;
  position: sticky;
  box-shadow: $box-shadow-sm;
  z-index: $zindex-sticky;
  background-color: $white;
}

// brand logo
.main-header .brand {
  &-logo {
    max-height: 56px;
    width: auto;
    @include media-breakpoint-up("xl") {
      max-height: 72px;
    }
  }
}

// quick nav
.main-header .quick-nav {
  .nav-item {
    flex-shrink: 1;
    max-width: 300px;
  }
  .nav-link {
    color: $body-color;
    display: flex;
    align-items: center;

    @include media-breakpoint-down("md") {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }

    &.active,
    &:hover {
      color: $primary;
    }
  }
  .nav-icon {
    display: inline-flex;
    font-size: 2.125rem;
    @include media-breakpoint-up("xl") {
      font-size: 2.25rem;
    }
  }
}

// secondary nav
.navbar-secondary-nav {
  @include list-unstyled;
  display: flex;
  margin-bottom: 0;

  .nav-link {
    color: $navbar-dark-color;
    padding-top: 0;
    padding-bottom: 0;
  }
  .nav-icon {
    display: inline-flex;
    font-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
  }
}

// language
.navbar-secondary-nav .dropdown {
  display: inline-flex;

  .dropdown-toggle {
    color: $navbar-dark-color;
    border: none;
    background-color: transparent;
    padding: 0;

    &::after {
      display: none;
    }

    &:hover {
      color: $navbar-dark-hover-color;
    }
    > .dropdown-caret {
      font-size: 0.75rem;
      margin-left: 0.25rem;
      transform: scaleY(1);
      transition: transform 0.3s;
    }
  }
  .dropdown-toggle.show > .dropdown-caret {
    color: $primary;
    transform: scaleY(-1);
  }

  .dropdown-menu {
    border-color: $white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &::before {
      content: "";
      left: 1.5rem;
      top: -7px;
      position: absolute;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
    }
  }
  .dropdown-menu-end::before {
    left: auto;
    right: 1.5rem;
  }

  .dropdown-icon {
    color: $gray-500;
  }
  .dropdown-item:hover {
    .dropdown-icon {
      color: $primary;
    }
  }
  .dropdown-item:active {
    .dropdown-icon {
      color: $white;
    }
  }
}

// search toggler
.search-toggler {
  color: $navbar-dark-color;
  display: flex;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  position: relative;
  background-color: transparent;

  &-icon {
    font-size: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    &:first-child {
      opacity: 1;
    }

    &:last-child {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      opacity: 0;
    }
  }

  &:hover {
    color: $navbar-dark-hover-color;
  }
}

.search-toggler:not(.collapsed) .search-toggler-icon {
  color: $primary;

  &:first-child {
    opacity: 0;
  }

  &:last-child {
    opacity: 1;
  }
}

// search collapse
.searchbar {
  height: auto !important;
  max-height: calc(100vh - 130px);
  @include media-breakpoint-up("xl") {
    max-height: calc(100vh - 152px);
  }
  overflow-y: auto;
  top: 100%;
  left: 0;
  right: 0;
  position: absolute;
  background-color: $white;
  box-shadow: 0 0 1rem 0.5rem rgba($color: #000000, $alpha: 0.15);
  transition: none;
  z-index: 1;

  .search-section {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.15s;
  }
}

.searchbar.show {
  .search-section {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-list {
  li {
    margin-bottom: 0.5rem;
  }
  a {
    color: $body-color;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.main-content::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: $black, $alpha: 0.4);
  z-index: calc($zindex-sticky - 1);
  visibility: hidden;
  opacity: 0;
  backdrop-filter: blur(5px);
  transition: opacity 0.3s, visibility 0.3s;
}

.has-search .main-content::after {
  opacity: 1;
  visibility: visible;
}

.navbar-nav-scroll {
  max-height: var(--#{$variable-prefix}scroll-height, 75vh);
  overflow-y: auto;
}

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  // scss-docs-start navbar-css-vars
  --#{$prefix}navbar-padding-x: #{if(
      $navbar-padding-x == null,
      0,
      $navbar-padding-x
    )};
  --#{$prefix}navbar-padding-y: #{$navbar-padding-y};
  --#{$prefix}navbar-color: #{$navbar-light-color};
  --#{$prefix}navbar-hover-color: #{$navbar-light-hover-color};
  --#{$prefix}navbar-disabled-color: #{$navbar-light-disabled-color};
  --#{$prefix}navbar-active-color: #{$navbar-light-active-color};
  --#{$prefix}navbar-brand-padding-y: #{$navbar-brand-padding-y};
  --#{$prefix}navbar-brand-margin-end: #{$navbar-brand-margin-end};
  --#{$prefix}navbar-brand-font-size: #{$navbar-brand-font-size};
  --#{$prefix}navbar-brand-color: #{$navbar-light-brand-color};
  --#{$prefix}navbar-brand-hover-color: #{$navbar-light-brand-hover-color};
  --#{$prefix}navbar-nav-link-padding-x: #{$navbar-nav-link-padding-x};
  --#{$prefix}navbar-toggler-padding-y: #{$navbar-toggler-padding-y};
  --#{$prefix}navbar-toggler-padding-x: #{$navbar-toggler-padding-x};
  --#{$prefix}navbar-toggler-font-size: #{$navbar-toggler-font-size};
  --#{$prefix}navbar-toggler-icon-bg: #{escape-svg(
      $navbar-light-toggler-icon-bg
    )};
  --#{$prefix}navbar-toggler-border-color: #{$navbar-light-toggler-border-color};
  --#{$prefix}navbar-toggler-border-radius: #{$navbar-toggler-border-radius};
  --#{$prefix}navbar-toggler-focus-width: #{$navbar-toggler-focus-width};
  --#{$prefix}navbar-toggler-transition: #{$navbar-toggler-transition};
  // scss-docs-end navbar-css-vars

  position: relative;
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: var(--#{$prefix}navbar-padding-y) var(--#{$prefix}navbar-padding-x);
  @include gradient-bg();

  &::before {
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: block;
    border-top: 1px solid #244b66;
    z-index: 2;
  }

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properties so that content nested within behave properly.
  // The `flex-wrap` property is inherited to simplify the expanded navbars
  %container-flex-properties {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }

  > .container,
  > .container-fluid {
    @extend %container-flex-properties;
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    > .container#{breakpoint-infix($breakpoint, $container-max-widths)} {
      @extend %container-flex-properties;
    }
  }
}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  padding-top: var(--#{$prefix}navbar-brand-padding-y);
  padding-bottom: var(--#{$prefix}navbar-brand-padding-y);
  margin-right: var(--#{$prefix}navbar-brand-margin-end);
  @include font-size(var(--#{$prefix}navbar-brand-font-size));
  color: var(--#{$prefix}navbar-brand-color);
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap;

  &:hover,
  &:focus {
    color: var(--#{$prefix}navbar-brand-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  // scss-docs-start navbar-nav-css-vars
  --#{$prefix}nav-link-padding-x: 0;
  --#{$prefix}nav-link-padding-y: #{$nav-link-padding-y};
  @include rfs($nav-link-font-size, --#{$prefix}nav-link-font-size);
  --#{$prefix}nav-link-font-weight: #{$nav-link-font-weight};
  --#{$prefix}nav-link-color: var(--#{$prefix}navbar-color);
  --#{$prefix}nav-link-hover-color: var(--#{$prefix}navbar-hover-color);
  --#{$prefix}nav-link-disabled-color: var(--#{$prefix}navbar-disabled-color);
  // scss-docs-end navbar-nav-css-vars

  display: flex;
  flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  // mobile view
  @include media-breakpoint-down("xl") {
    margin-left: auto;
    margin-right: auto;
    padding-left: calc($container-padding-x / 2);
    padding-right: calc($container-padding-x / 2);
    max-width: map-get($map: $container-max-widths, $key: lg);

    .nav-item {
      opacity: 0;
      transform: translateY(2rem);
      transition: all 0.25s ease;
      @for $i from 1 through 8 {
        &:nth-child(#{$i}n) {
          transition-delay: #{$i * 0.05}s;
        }
      }
    }

    .nav-link {
      font-size: 1.25rem;
    }
  }

  @include media-breakpoint-down("lg") {
    max-width: map-get($map: $container-max-widths, $key: md);
  }

  @include media-breakpoint-down("md") {
    max-width: map-get($map: $container-max-widths, $key: sm);
  }

  .show > .nav-link,
  .nav-link.active {
    color: var(--#{$prefix}navbar-active-color);
  }

  .dropdown-menu {
    position: static;
  }
}

.navbar-collapse.show {
  .navbar-nav .nav-item {
    opacity: 1;
    transform: translateY(0);
  }
}

// Navbar text
//
//

.navbar-text {
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
  color: var(--#{$prefix}navbar-color);

  a,
  a:hover,
  a:focus {
    color: var(--#{$prefix}navbar-active-color);
  }
}

// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orientation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  align-items: center;

  // mobile view
  @include media-breakpoint-down("xl") {
    top: 100%;
    left: 0;
    right: 0;
    background-color: $dark;
    position: absolute;
    min-height: 100%;
    height: calc(100vh - 130px) !important;
    overflow-y: auto;
    overflow-x: hidden;

    &::before {
      content: "";
      position: fixed;
      top: 130px;
      left: 0;
      right: 17px;
      display: block;
      height: 3rem;
      background: linear-gradient(
        180deg,
        rgba(18, 42, 60, 1) 1%,
        rgba(18, 42, 60, 60) 58%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 1;
    }
    transition: none;
  }
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  display: inline-flex;
  padding: var(--#{$prefix}navbar-toggler-padding-y)
    var(--#{$prefix}navbar-toggler-padding-x);
  @include font-size(var(--#{$prefix}navbar-toggler-font-size));
  line-height: 1;
  color: var(--#{$prefix}navbar-color);
  background-color: transparent; // remove default button style
  border: none;
  @include border-radius(var(--#{$prefix}navbar-toggler-border-radius));
  @include transition(var(--#{$prefix}navbar-toggler-transition));

  height: 2.5rem;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
  color: $navbar-dark-color;
  display: block;
  width: 1em;
  height: 1em;

  line {
    transform-origin: center;
    transition: 0.2s;
    stroke-width: 3;
    &:nth-of-type(2) {
      opacity: 1;
    }
  }
}

.navbar-toggler:not(.collapsed) {
  .navbar-toggler-icon {
    color: $primary;
  }
  .navbar-toggler-icon line:nth-of-type(1) {
    transform-origin: 80% 35%;
    transform: rotate(-45deg);
  }

  .navbar-toggler-icon line:nth-of-type(2) {
    opacity: 0;
  }

  .navbar-toggler-icon line:nth-of-type(3) {
    transform-origin: 80% 65%;
    transform: rotate(45deg);
  }
}

.navbar-toggler-text {
  font-size: 0.875rem;
  text-transform: uppercase;
  margin: auto 0.5rem;
}

.navbar-nav-scroll {
  max-height: var(--#{$prefix}scroll-height, 75vh);
  overflow-y: auto;
}

// scss-docs-start navbar-expand-loop
// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand-lg {
  @include media-breakpoint-up("xl") {
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: calc(var(--#{$prefix}navbar-nav-link-padding-x) * 1.5);
        padding-left: calc(var(--#{$prefix}navbar-nav-link-padding-x) * 1.5);
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: flex !important; // stylelint-disable-line declaration-no-important
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }

    //   .offcanvas {
    //     // stylelint-disable declaration-no-important
    //     position: static;
    //     z-index: auto;
    //     flex-grow: 1;
    //     width: auto !important;
    //     height: auto !important;
    //     visibility: visible !important;
    //     background-color: transparent !important;
    //     border: 0 !important;
    //     transform: none !important;
    //     @include box-shadow(none);
    //     @include transition(none);
    //     // stylelint-enable declaration-no-important

    //     .offcanvas-header {
    //       display: none;
    //     }

    //     .offcanvas-body {
    //       display: flex;
    //       flex-grow: 0;
    //       padding: 0;
    //       overflow-y: visible;
    //     }
  }
}

// scss-docs-end navbar-expand-loop

// Navbar themes
//
// Styles for switching between navbars with light or dark background.

//   .navbar-light {
//     @include deprecate("`.navbar-light`", "v5.2.0", "v6.0.0", true);
//   }

.navbar-dark {
  // scss-docs-start navbar-dark-css-vars
  --#{$prefix}navbar-color: #{$navbar-dark-color};
  --#{$prefix}navbar-hover-color: #{$navbar-dark-hover-color};
  --#{$prefix}navbar-disabled-color: #{$navbar-dark-disabled-color};
  --#{$prefix}navbar-active-color: #{$navbar-dark-active-color};
  --#{$prefix}navbar-brand-color: #{$navbar-dark-brand-color};
  --#{$prefix}navbar-brand-hover-color: #{$navbar-dark-brand-hover-color};
  --#{$prefix}navbar-toggler-border-color: #{$navbar-dark-toggler-border-color};
  --#{$prefix}navbar-toggler-icon-bg: #{escape-svg($navbar-dark-toggler-icon-bg)};
  // scss-docs-end navbar-dark-css-vars
}

.quicklink-img {
  max-width: 36px;
  max-height: 36px;
}

@keyframes navitemSlideUp {
  0% {
    transform: translateY(16px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }

  0% {
    transform: translateY(16px);
    opacity: 0;
  }
}

@keyframes dropdownSlideIn {
  0% {
    transform: translateY(-8px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }

  0% {
    transform: translateY(-8px);
    opacity: 0;
  }
}
