


body {
  overflow-x: hidden;
}


.skip-navigation {
  border: 0!important;
  height: 0.0625rem!important;
  overflow: hidden!important;
  padding: 2px !important;
  position: fixed !important;
  width: 0.0625rem!important;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
  z-index: $zindex-fixed + 1;
  font-weight: bold;

  &:focus {
    text-decoration: none;
    width: auto !important;
    height: auto !important;
    text-align: center;
    border: 0;
    transform: translate(-50%, 0%);
  }
}


.main-content {
  overflow: hidden;
  @include media-breakpoint-up('md') {
   
}
}

