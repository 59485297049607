.form-membership {
  min-height: calc(100vh - 130px);

  @include media-breakpoint-up("xl") {
    min-height: calc(100vh - 152px);
  }
}

.step-list {
  &-item {
  }
  &-item.active .step-list-indicator {
    background-color: $primary;
  }
  &-indicator {
    display: block;
    color: transparent;
    text-align: center;
    background-color: #edf4fa;
    border-radius: $border-radius-pill;
  }
}

.language-list {
  &-item {
  }
  &-control {
    height: 0;
    width: 0;
  }
  &-control {
    &:checked + .language-list-label {
      color: $white;
      background-color: $primary;
    }
    &:focus + .language-list-label {
      border-color: $primary;
      box-shadow: $btn-focus-box-shadow;
    }
  }
  &-label {
    transition: 0.15s ease-in-out;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $border-color;
    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}

.img-upload {
  width: 21rem;
}