.hero-article {
    position: relative;
    min-height: 24rem;
    max-height: 32rem;

    &-cover {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        margin-bottom: 0;

        &::after {
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: $black, $alpha: .5);
            position: absolute;
            z-index: 0;
        }

        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &-headline {
        position: relative;
        z-index: 1;
    }
}